@import '~antd/dist/antd.css';
.custom-green-bg .ant-select-selector {
  background-color: #00990044 !important;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.ant-table-tbody>tr .ant-table-wrapper:only-child .ant-table {
  margin: 0;
}